
/*
** partners
*/

.partners {
	display: flex;
	width: 100%;
}

.partners__item {
	margin-right: 1em;
}

.partners__anchor:hover {
	opacity: 0.3;
}

.partners__item img {
	height: 6em;
	width: auto !important;
}