
/*
** section
*/

.section {
	transition: filter 600ms;
}

body.body--header-opened .section {
	filter: blur(0.5em);
}