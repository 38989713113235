
/*
** paragraphs
*/

.paragraphs {

}

.paragraphs__container {

}

.paragraphs__item {
	position: relative;
}

.paragraphs__item:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 1;

	content: '';
	background-color: currentColor;
	opacity: 0.2;

}

/* content */
.paragraphs__content {

}

.paragraphs__wrapper {

}

/* image */
.paragraphs__image {

}

/* info */
.paragraphs__info {

}

.paragraphs__icon {
	display: none;
}

.paragraphs__name {
	margin-bottom: 1rem;

	font-family : var(--5xlarge-ff);
	font-weight: var(--5xlarge-fw);
	font-size: var(--5xlarge-fs);
	letter-spacing: var(--5xlarge-ls);
	line-height: var(--5xlarge-lh);
	color: var(--5xlarge-c);
}

.paragraphs__text {

}

.paragraphs__pdfs {
	margin-top: 2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.paragraphs__item:not(:last-child) {
		padding-bottom: 7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.paragraphs__item:not(:last-child) {
		padding-bottom: 3em;
	}
}