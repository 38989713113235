
/*
** home
*/

body.body--home .logo {
	color: #fff;
}

.section--home .page__header {
	display: none;
}

.section--home .page {
	padding-top: 0;
}

/* productions */
.section--home .page__productions {
	text-align: center;
}

.section--home .page__productions .productions {
	text-align: left;

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.section--home .page__productions .more {
		margin-top: 2em;
		margin-bottom: 5em;
	}

	.section--home .page__productions .productions__item:not(:last-child) {
		border-right: 1px solid currentColor;
		border-right: 1px solid color-mix(in srgb, currentColor 20%, transparent);
	}
}
/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.section--home .page__productions .more {
		margin-top: 2em;
		margin-bottom: 4em;
	}
}