
/*
** articles
*/

.articles {

}

.articles__item {

}

.articles__wrapper {
	position: relative;
}

.articles__thumbnail {

}

.articles__info {
	margin-top: 0.5em;
}

.articles__name {
	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);
}

.articles__link {

}

.articles__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.articles__item .articles__thumbnail {
		transition: opacity 600ms;
	}

	.articles__item:hover .articles__thumbnail {
		opacity: 0.3;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}