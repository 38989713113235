
/*
** socials
*/

.socials {
	display: flex;
	align-items: center;
	column-gap: 1em;
}

.socials__item {

}

.socials .button__label {
	display: none;
}

.socials .button:hover {
	opacity: 0.3;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.socials {
		flex-wrap: wrap;
		justify-content: center;
	}

	.socials__item:last-child {
		width: 100%;
		text-align: center;
	}
}