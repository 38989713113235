
/*
** panels
*/

.panels {

}

.panels__container {

}

.panels__item {

}

.panels__name {
	font-family : var(--6xlarge-ff);
	font-weight: var(--6xlarge-fw);
	font-size: var(--6xlarge-fs);
	letter-spacing: var(--6xlarge-ls);
	line-height: var(--6xlarge-lh);
	color: var(--6xlarge-c);

	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;

	border-top: 1px solid color-mix(in srgb, currentColor 20%, transparent);
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.panels__text {
	margin: auto;
	text-align: center;
}

.panels__pdfs {
	margin-top: 2em;
}

/* videos */
.panels .videos__container {
	max-width: none;
}

/* works */
.panels .works__item:first-child .works__info {
	border-top: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.panels__item:not(:last-child) {
		padding-bottom: 7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.panels__item:not(:last-child) {
		padding-bottom: 3em;
	}
}