
/*
** wysiwyg
*/

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6,
.wysiwyg hr,
.wysiwyg ul,
.wysiwyg blockquote {
	display: none;
}

.wysiwyg ul,
.wysiwyg blockquote,
.wysiwyg p {
	margin: 0;
	padding: 0;
	margin-bottom: 2rem;
}

/* p */
.wysiwyg p {

}

.wysiwyg p:last-child {
	margin-bottom: 0em;
}

/* a */
.wysiwyg a:not(.box) {
	transition: border 300ms;
	border-bottom: 1px solid currentColor;
}

.wysiwyg a:not(.box):hover {
	border-bottom: 1px solid transparent;
}

.wysiwyg a.box {
	margin-bottom: 0.4em;
}

/* strong */
.wysiwyg strong {
	font-weight: bold;
}

/* blockquote */
.wysiwyg blockquote {

}

.wysiwyg blockquote:last-child {
	margin-bottom: 0em;
}

/* ul  */
.wysiwyg ul {
	list-style: none;
}

.wysiwyg ul li {
	position: relative;

	padding: 0.3em 0em;

	border-bottom: 1px solid currentColor;
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.wysiwyg ul li:first-child {
	border-top: 1px solid currentColor;
	border-top: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}
