
/*
** close
*/

.close {
	position: relative;
	cursor: pointer;
	width: 1em;
	height: 1em;
}

.close::before,
.close::after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;
	height: 2px;
	width: 1em;

	margin-left: -0.5em;
	background: currentColor;
}

.close::before {
	transform: rotate(45deg);
}

.close::after {
	transform: rotate(-45deg);
}