
/*
** strip
*/

.strip {
	position: relative;
	padding-top: 1em;
	padding-bottom: 0.7em;
}

.strip:before {
	position: absolute;
	top: 0%;
	left: -20%;
	width: 140%;
	height: 1px;
	z-index: 1;

	content: '';
	background-color: currentColor;
	opacity: 0.1;
}

.strip:after {
	position: absolute;
	top: 100%;
	left:-20%;
	width: 140%;
	height: 1px;
	z-index: 1;

	content: '';
	background-color: currentColor;
	opacity: 0.1;
}