
/*
** works
*/

.works {

}

.works__container {
	text-align: center;
}

.works__item {
	position: relative;
}

.works__wrapper {
	position: relative;
}

.works__thumbnail {
	display: none;
}

/* info */
.works__info {
	position: relative;
	border-top: 1px solid currentColor;
	border-top: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.works__item:last-child .works__info {
	border-bottom: 1px solid currentColor;
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.works__name {
	font-family : var(--medium-ff);
	font-weight: var(--medium-fw);
	font-size: var(--medium-fs);
	letter-spacing: var(--medium-ls);
	line-height: var(--medium-lh);
	color: var(--medium-c);
	white-space: nowrap;
}

.works__link {

}

.works__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
	opacity: 0;
}

/* hover */
.works__item:hover {
	color: var(--bgcolor);
}

.works__info:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	z-index: -1;

	content: '';
	background-color: var(--color);
	transition: height 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.works__item:hover .works__info:after {
	height: 100%;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.works__name svg {
		opacity: 0;
	}

	.works__item:hover .works__name svg {
		opacity: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.works__info {
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.works__name svg {
		display: none;
	}
}
