
/*
** teams
*/

.teams {

}

.teams__container {

}

.teams__item {
	position: relative;
}

.teams__wrapper {

}

.teams__thumbnail {

}

.teams__info {
	margin-top: 1em;
}

.teams__name {
	font-family : var(--large-ff);
	font-weight: var(--large-fw);
	font-size: var(--large-fs);
	letter-spacing: var(--large-ls);
	line-height: var(--large-lh);
	color: var(--large-c);
}

.teams__function {
	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);
}

.teams__more {
	margin-top: 1rem;

	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	font-size: 0.75em;
	text-transform: uppercase;
}

.teams__link {

}

.teams__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.teams__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.teams__item {
		width: 33.3%;

		padding: 5em;
	}

	.teams__thumbnail {
		display: none;
	}

	/* hover */
	.teams__name {
		transition: transform 600ms;
	}

	.teams__item:hover .teams__name {
		position: relative;
		z-index: 11;
		pointer-events: none;

		color: #fff;
		mix-blend-mode: difference;
		transform: translate(1rem, -1rem);
	}

	.teams__item:hover .teams__function,
	.teams__item:hover .teams__more {
		opacity: 0;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.teams__info {
		padding-left: 1em;
		padding-right: 1em;
	}

	.teams__item {
		margin-bottom: 3em;
	}
}