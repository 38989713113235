/*
** swiper - slideshow
** (overload : node_modules/swiper/css/swiper.css)
*/

/* color */
body .swiper.slideshow,
body .swiper.slideshow .swiper-slide,
body .swiper.slideshow .swiper-slide:after {
	color: var(--color);
	background-color: var(--bgcolor);
}

/* swiper */
body .swiper.slideshow {
	position: relative;
	width: 100%;
	height: var(--swp-sw-height);
	z-index: 1;
}

body .swiper.slideshow .swiper-wrapper {
	user-select: none;
}

/* slide bug lazy / fadeEffect */
body .swiper.slideshow .swiper-slide .swiper-image {
	transform: translateY(-9999px);
}

body .swiper.slideshow .swiper-slide.swiper-slide-active .swiper-image,
body .swiper.slideshow .swiper-slide.swiper-slide-prev   .swiper-image,
body .swiper.slideshow .swiper-slide.swiper-slide-next   .swiper-image {
	transform: translateY(0);
}

/* image */
body .swiper.slideshow .swiper-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc( var(--swp-sw-height) - 5em );
	z-index: -1;
}

body .swiper.slideshow .swiper-img {
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	object-position: center;
	object-fit: contain;
}

/* lazy */
body .swiper.slideshow .swiper-lazy-loading {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;

	background-color: var(--bgcolor);
}

/* overlay */
body .swiper.slideshow .swiper-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	background-color: transparent;
}

/* caption */
body .swiper.slideshow .swiper-caption {
	position: absolute;
	bottom: 2em;
	left: 2em;
	width: 30em;
	z-index: 100;
}

/* pagination */
body .swiper.slideshow .swiper-pagination {
	bottom: 0;

	font-family : var(--2xsmall-ff);
	font-weight: var(--2xsmall-fw);
	font-size: var(--2xsmall-fs);
	letter-spacing: var(--2xsmall-ls);
	line-height: 3rem;
	color: var(--2xsmall-c);
}

/* prev next */
body .swiper.slideshow .swiper-button-prev,
body .swiper.slideshow .swiper-button-next {
	top: auto;
	bottom: 0;
	left: 50%;
	right: auto;

	width: 3em;
	height: 3em;
	z-index: 15;

	transition: opacity 600ms;
}

body .swiper.slideshow.multiple .swiper-wrapper:after {
	position: absolute;
	bottom: 0.25em;
	left: 50%;
	width: 9em;
	height: 2.5em;
	z-index: 1;

	transform: translateX(-50%);

	content: '';
	border: 1px solid currentColor;
	border-radius: 2em;
}

body .swiper.slideshow .swiper-button-prev {
	transform: translateX(-4.5em);
}

body .swiper.slideshow .swiper-button-next {
	transform: translateX(1.5em);
}

body .swiper.slideshow .swiper-button-prev:before,
body .swiper.slideshow .swiper-button-next:before {
	content: '→';
	color: var(--color);
}

body .swiper.slideshow .swiper-button-prev:before {
	content: '←';
}

body .swiper.slideshow .swiper-button-prev:after,
body .swiper.slideshow .swiper-button-next:after {
	display: none;
}

body .swiper.slideshow .swiper-button-prev:hover,
body .swiper.slideshow .swiper-button-next:hover {
	opacity: 0.3;
}

body .swiper.slideshow .swiper-button-prev:focus,
body .swiper.slideshow .swiper-button-next:focus {
	outline: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	:root {
		--swp-sw-height: 80vh;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	:root {
		--swp-sw-height: 50vh;
	}
}