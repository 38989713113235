
/*
** marquee
*/

.marquee {
	display: flex;
	align-items: center;
	overflow: hidden;

	user-select: none;
}

.marquee__item {

}

.marquee__item .str {
	display: inline-block;
	transform: translateY(0.05em);
}

.marquee__item .ico {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
}