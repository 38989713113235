
/*
** nprogress (z-index: 1010)
*/

#nprogress {
	pointer-events: none;
}

#nprogress .bar {
	background: currentColor;

	position: fixed;
	z-index: 1010;
	top: 0;
	left: 0;

	width: 100%;
	height: 1px;
}

#nprogress .peg {
	display: block;

	position: absolute;
	right: 0px;
	width: 50px;
	height: 50%;
	opacity: 1.0;

	transform: rotate(3deg) translate(0px,-4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
	margin-top: -0.5em;
	margin-left: -0.5em;

	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1010;
}

#nprogress .spinner-icon {
	width: 1em;
	height: 1em;
	background-color: currentColor;

	border-radius: 100%;
	animation: loader-animation 1.0s infinite ease-in-out;
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
	position: absolute;
}

