
/* html */
html {
	font-size: 100%;
	height: 100%;
}

/* body */
body {
	font-family:  'PP Neue Montreal', sans-serif;
	font-size: 1em;
	line-height: var(--lh-normal);
	font-weight: var(--fw-normal);
	letter-spacing: 0.03em;

	color: var(--color);
	background-color: var(--bgcolor);

	overflow-x: hidden;
	overflow-y: scroll;
}

/* h1, h2, h3, h4, h5, h6 */
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

b, strong {
	font-weight: 600;
}

/* box sizing */
*, *:before, *:after {
	box-sizing: border-box;
}

/* selection */
::selection {

}

img::selection {
	background-color: transparent;
}

/* anchor */
a {
	color: inherit;
	text-decoration: none;
}

a:focus {
	outline: none;
}

/* figure */
figure {
	margin: 0;
	line-height: 0;
	color: transparent;
}

/*
** 320 x 480
** 360 x 640
** 768 x 1024
** 800 x 1280
** 1280 x 500
** 1920 x 900
*/


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.mw-xs { max-width: 20em; }

	.mw-s  { max-width: 25em; }

	.mw-n  { max-width: 44em; }

	.mw-l  { max-width: 55em; }

	.mw-xl { max-width: 65em; }
}


@media only screen and ( min-width: 1600px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}


/* large screen */
@media only screen and ( min-width: 1920px ) {

	body {
		font-size: 1vw;
	}
}

