
/*
** sections - 2
*/

.sections.sections-2 {
	display: flex;
	column-gap: 2em;
}

.sections.sections-2 .sections__item {

}

.sections.sections-2 .sections__anchor {
	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase
}

.sections.sections-2 .sections__anchor:hover,
.sections.sections-2 .sections__anchor.selected {
	opacity: 0.3;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.sections.sections-2 {
		white-space: nowrap;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 641px ) {

	.sections.sections-2 {
		max-width: 17em;
		margin: auto;

		row-gap: 1em;
		flex-wrap: wrap;
		justify-content: center;
	}
}