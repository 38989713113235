
/*
** main
*/

.main {
	width: 100%;
	overflow: hidden;
}

.main:after {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	z-index: 3;

	content: '';
	background-color: var(--color);
	opacity: 0;
	transition: opacity 600ms;
}

body.body--header-opened .main:after {
	height: 100%;
	opacity: 0.75;
}
