
/*
** page - article
*/


.page.article .page__info {

}

.page.article .page__name {
	font-family : var(--4xlarge-ff);
	font-weight: var(--4xlarge-fw);
	font-size: var(--4xlarge-fs);
	letter-spacing: var(--4xlarge-ls);
	line-height: var(--4xlarge-lh);
	color: var(--4xlarge-c);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.article .page__content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2em;

	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}