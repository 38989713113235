
/*
** productions
*/

.productions {

}

.productions__container {

}

.productions__item {

}

.productions__wrapper {
	position: relative;
}

.productions__period {
	margin-bottom: 0.2em;

	font-family : var(--xlarge-ff);
	font-weight: var(--xlarge-fw);
	font-size: var(--xlarge-fs);
	letter-spacing: var(--xlarge-ls);
	line-height: var(--xlarge-lh);
	color: var(--xlarge-c);

	text-transform: uppercase;
	white-space: nowrap;
}

.productions__name {
	font-family : var(--5xlarge-ff);
	font-weight: var(--5xlarge-fw);
	font-size: var(--5xlarge-fs);
	letter-spacing: var(--5xlarge-ls);
	line-height: var(--5xlarge-lh);
	color: var(--5xlarge-c);
}

.productions__creation,
.productions__place {
	font-family : var(--xsmall-ff);
	font-weight: var(--xsmall-fw);
	font-size: var(--xsmall-fs);
	letter-spacing: var(--xsmall-ls);
	line-height: var(--xsmall-lh);
	color: var(--xsmall-c);
}

.productions__link {

}

.productions__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.productions__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.productions__item {
		width: 50%;
		padding: 5em;
	}

	.productions__item:nth-child(7n+5) {
		margin-right: 33.3%;
	}

	.productions__item:nth-child(7n+6) {
		margin-left: 33.3%;
	}

	/* hover */
	.productions__item .productions__wrapper {
		transition: transform 600ms, opacity 300ms;
	}

	.productions__item:hover .productions__wrapper {
		opacity: 0.3;
		transform: translateY(-1rem);
	}
}

@media only screen and ( min-width: 1280px ) {

	.productions__item {
		width: 33.3%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.productions__item {
		padding: 2em 2em 2.5em;
	}
}