
/*
** footer
*/

body.body--transition .footer {

}

.footer {
	transition: filter 600ms;
}

body.body--header-opened .footer {
	filter: blur(0.5em);
}

/* content */
.footer__content {
	color: #fff;
	background-color: #000;
}

.footer__partners {

}

.footer__logo svg {
	width: 100%;
	height: 20%;
}

/* baseline */
.footer__baseline {
	position: relative;
}

.footer__baseline:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 1;

	content: '';
	background-color: currentColor;
	opacity: 0.4;

}

.footer__navigation {
	display: flex;
}

.footer__socials {

}

/* font */
.footer__copyright,
.footer__socials {
	font-family : var(--2xsmall-ff);
	font-weight: var(--2xsmall-fw);
	font-size: var(--2xsmall-fs);
	letter-spacing: var(--2xsmall-ls);
	line-height: var(--2xsmall-lh);
	color: var(--2xsmall-c);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.footer__partners {
		height: 17em;
	}

	.footer__baseline .wrapper {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;

		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	.footer__socials {
		justify-self: end;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.footer__logo {
		position: relative;
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.footer__logo:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		z-index: 1;

		content: '';
		background-color: currentColor;
		opacity: 0.4;
	}

	.footer__copyright {
		white-space: nowrap;
	}

	.footer__navigation {
		justify-content: center;
	}

	.footer__socials {
		margin-top: 1.5rem;
	}
}