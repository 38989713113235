
/*
** root
*/

:root {

	--color: #fff;
	--bgcolor: #000;

	--block-width: 60rem;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	:root {
		--padding-wrapper: 3rem;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	:root {
		--padding-wrapper: 1rem;
	}
}


