
/*
** top
*/

.top {
	text-align: center;
}

.top.top--hidden {
	opacity: 0;
	pointer-events: none;
}

.top svg {
	display: block;
	margin: auto;
}

.top__anchor {
	font-family : var(--xsmall-ff);
	font-weight: var(--xsmall-fw);
	font-size: var(--xsmall-fs);
	letter-spacing: var(--xsmall-ls);
	line-height: var(--xsmall-lh);
	color: var(--xsmall-c);

	text-transform: uppercase;
}

.top__anchor:hover {
	opacity: 0.3;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.top {
		display: none;
	}
}