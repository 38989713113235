
/*
** paragraphs - fullscreen
*/

.paragraphs__item.fullscreen {

}

.paragraphs__item.fullscreen .paragraphs__wrapper {
	max-width: var(--block-width);
	margin: auto;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}