
/*
** wysiwyg - full
*/

.wysiwyg.wysiwyg--full h3 {
	display: block;
	margin-bottom: 0.5rem;

	font-family : var(--5xlarge-ff);
	font-weight: var(--5xlarge-fw);
	font-size: var(--5xlarge-fs);
	letter-spacing: var(--5xlarge-ls);
	line-height: var(--5xlarge-lh);
	color: var(--5xlarge-c);
}

.wysiwyg.wysiwyg--full h4 {
	display: block;
	margin-bottom: 0.5rem;

	font-family : var(--2xlarge-ff);
	font-weight: var(--2xlarge-fw);
	font-size: var(--2xlarge-fs);
	letter-spacing: var(--2xlarge-ls);
	line-height: var(--2xlarge-lh);
	color: var(--2xlarge-c);
}

.wysiwyg.wysiwyg--full h5 {
	display: block;
	margin-bottom: 0.5rem;

	font-family : var(--xlarge-ff);
	font-weight: var(--xlarge-fw);
	font-size: var(--xlarge-fs);
	letter-spacing: var(--xlarge-ls);
	line-height: var(--xlarge-lh);
	color: var(--xlarge-c);
}

.wysiwyg.wysiwyg--full h6 {
	display: block;
	margin-bottom: 0.5rem;

	font-family : var(--medium-ff);
	font-weight: var(--medium-fw);
	font-size: var(--medium-fs);
	letter-spacing: var(--medium-ls);
	line-height: var(--medium-lh);
	color: var(--medium-c);
}

.wysiwyg.wysiwyg--full p {
	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}

.wysiwyg.wysiwyg--full ul {
	display: block;

	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}

.wysiwyg.wysiwyg--full p.small {
	display: block;

	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);
}

.wysiwyg.wysiwyg--full blockquote {
	display: block;
}

.wysiwyg.wysiwyg--full blockquote h3,
.wysiwyg.wysiwyg--full blockquote h4,
.wysiwyg.wysiwyg--full blockquote h5,
.wysiwyg.wysiwyg--full blockquote h6,
.wysiwyg.wysiwyg--full blockquote p {
	font-family : var(--4xlarge-ff);
	font-weight: var(--4xlarge-fw);
	font-size: var(--4xlarge-fs);
	letter-spacing: var(--4xlarge-ls);
	line-height: var(--4xlarge-lh);
	color: var(--4xlarge-c);
}

.wysiwyg.wysiwyg--full blockquote strong {
	font-weight: 500;
}