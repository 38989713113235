
/*
** preloader (z-index: 1000)
*/

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;

	background-color: #fff;
	transition: clip-path 800ms;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 90vw 100%, 90vw 101%, 10vw 101%, 10vw 100%, 0 100%);
}

/* hide */
body .preloader.hide {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 100% 0, 0 0, 0 100%, 0 100%);
}

/* content */
.preloader .preloader__content {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	transform: translate(-50%, -50%);
	pointer-events: none;
}

/* hidden */
body .preloader.hidden {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}