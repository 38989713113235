
/*
** calendar
*/

.section--calendar .page .page__name {
	font-family : var(--5xlarge-ff);
	font-weight: var(--5xlarge-fw);
	font-size: var(--5xlarge-fs);
	letter-spacing: var(--5xlarge-ls);
	line-height: var(--5xlarge-lh);
	color: var(--5xlarge-c);

	text-align: center;
}

.section--calendar .page .page__name .label {
	color: #fff;

	-webkit-text-stroke: 2px var(--color);
	text-stroke: 2px var(--color);
}

.section--calendar .page .page__name .box.year {
	padding-left: 0.35em;
	padding-right: 0.35em;
}

.section--calendar .page .page__name .box.year:after {
	background-color: #fff;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {


	/* name */
	.section--calendar .page .page__name {
		font-size: 9em;
	}

	.section--calendar .page .page__name .box.year {
		margin-left: -0.3em;
	}

	.section--calendar .page .page__name .box.year:before {
		position: absolute;
		top: -50%;
		right: -40%;
		width: 300px;
		height: 300px;
		z-index: -1;

		content: '';
		background: transparent url('/assets/img/calendar.gif') no-repeat;
		background-size: 100%;
	}

	/* navigation */
	.section--calendar .page .navigation {
		position: absolute;
		top: 1.5em;
		left: var(--padding-wrapper);
		z-index: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	/* name */
	.section--calendar .page .page__name {
		font-size: 6em;
	}

	.section--calendar .page .page__name .box.year {
		margin-top: -2.5em;
	}

	/* navigation */
	.section--calendar .page .navigation {
		justify-content: center;
	}

	/* productions */
	.section--calendar .page__productions {
		margin-top: 2em;
		text-align: center;
	}
}