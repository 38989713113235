
/*
** videos
*/

.videos {

}

.videos__container {
	max-width: 60em;
	margin: auto;
}

.videos__wrapper {

}

.videos__embed {

}

.videos__text {
	margin-top: 1em;

	opacity: 0.5;
}