
/*
** swiper - slides (overload : node_modules/swiper/css/swiper.css)
*/

body .swiper.slides {

}

body .swiper.slides .swiper-wrapper {

}

body .swiper.slides .swiper-slide {
	padding-left: 0.5em;
	padding-right: 0.5em;
	padding-bottom: 5em;
}

body .swiper.slides .swiper-pagination {

}

body .swiper.slides .swiper-pagination-bullet-active {
	opacity: 1;
	background: var(--color);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	body .swiper.slides .swiper-slide {
		width: 30%;
	}

	body .swiper.slides .swiper-slide:first-child {
		padding-left: 2em;
	}

	body .swiper.slides .swiper-slide:last-child {
		padding-right: 2em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	body .swiper.slides .swiper-slide {
		width: 50%;
	}

	body .swiper.slides .swiper-slide:first-child {
		padding-left: 1em;
	}

	body .swiper.slides .swiper-slide:last-child {
		padding-right: 1em;
	}
}
