
/*
** header
** (sections 1 / burger)
** z-index: 100;
*/

.header {

}

.header__logo {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 100;

	transform: translateX(-50%);
}

body.body--header-opened .header__logo {
	display: none;
}

.header__flap {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100;

	color: var(--color);
	background-color: var(--bgcolor);
	transition: clip-path 600ms;
}

.header__navigation {

}

.header__burger {
	position: fixed;
	top: var(--padding-wrapper);
	right: var(--padding-wrapper);
	z-index: 101;

	transform: translateY(0.1em);
	overflow: hidden;
	cursor: pointer;
}

body.body--header-opened .header__burger .burger__label {
	opacity: 0;
	pointer-events: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.header .wrapper {
		padding-top: 1.5em;
	}

	.header__burger {
		top: 1.5em;
	}

	.header__flap {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 100% 0, 0 0, 0 100%, 0 100%);
	}

	body.body--header-opened .header__flap {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 97vw 100%, 97vw 33em, 3vw 33em, 3vw 100%, 0 100%);
	}

	.header__navigation {
		display: grid;
		column-gap: 2em;
		grid-template-columns: 1fr 1fr auto;
		grid-template-areas:
		'a b c'
		'd d d';
	}

	.header__navigation .sections-1 {
		grid-area: a;
	}

	.header__navigation .sections-2 {
		grid-area: b;
		justify-self: end;
	}

	.header__navigation .langs {
		grid-area: c;
		margin-right: 4em;
	}

	.header__navigation .socials {
		grid-area: d;
		justify-self: end;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.header__logo .logo__svg {
		width: 15em;
		height: 3em;
	}

	.header__flap {
		text-align: center;
		padding-top: 5em;
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
	}
	body.body--header-opened .header__flap {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}

	.header__navigation .sections-2 {
		margin-top: 2em;
	}

	.header .langs {
		position: absolute;
		top: var(--padding-wrapper);
		left: var(--padding-wrapper);
		z-index: 1;
	}

	.header .socials {
		margin-top: 5em;
	}
}


