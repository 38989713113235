
/*
** list
*/

.list {

}

.list__container {

}

.list__item:not(:last-child) {
	margin-bottom: 5em;
}

.list__wrapper {
	text-align: center;
}

.list__name {
	font-family : var(--3xlarge-ff);
	font-weight: var(--3xlarge-fw);
	font-size: var(--3xlarge-fs);
	letter-spacing: var(--3xlarge-ls);
	line-height: var(--3xlarge-lh);
	color: var(--3xlarge-c);
}

.list__content {
	margin-top: 1em;
}