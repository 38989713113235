
/*
** wrapper
*/

.wrapper {
	padding: var(--padding-wrapper);
}

.wrapper.no-gutter {
	padding-left: 0;
	padding-right: 0;
}

.wrapper.no-top {
	padding-top: 0;
}