
/*
** team
*/


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.section--teams .page__categories {
		position: absolute;
		top: 1.5em;
		left: var(--padding-wrapper);
		z-index: 1;
	}

	.section--teams .page.simple .page__content {
		margin-bottom: 0em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.section--teams .page__categories {
		text-align: center;
	}

	.section--teams .page.simple .page__teams {
		margin-top: 2em;
	}
}