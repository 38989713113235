
/*
** langs
*/

.langs {
	margin: 0;
	padding: 0;
	list-style: none;
}

.langs__item {

}

.langs__item.selected {
	display: none;
}

.langs__anchor {
	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase
}

.langs__anchor:hover {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 641px ) {

}