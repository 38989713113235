
/*
** image
*/

.image {
	display: inline-block;
}

.image__vignette {
	display: block;
	position: relative;
}