
/*
** members
*/

.members {

}

.members__item {
	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}

.members__item:not(:last-child) {
	margin-bottom: 0.2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}