
/*
** pagination
*/

.pagination {
	text-align: center;
}

.pagination__wrapper {
	display: inline-block;
	padding: 1em 2em;

	border-radius: 1em;
	box-shadow: 0 0.2em 0.4em 0 rgba(0, 0, 0, 0.15);
}

.pagination__item {
	display: inline-block;
	vertical-align: middle;

	padding: 0.5em;
}

.pagination__item--previous,
.pagination__item--next {
	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);

	text-transform: uppercase;
}

.pagination__item--previous svg,
.pagination__item--next svg {
	display: block;
	width: 3em;
	height: 2.25em;
}

.pagination__item--page {
	font-family : var(--xlarge-ff);
	font-weight: var(--xlarge-fw);
	font-size: var(--xlarge-fs);
	letter-spacing: var(--xlarge-ls);
	line-height: var(--xlarge-lh);
	color: var(--xlarge-c);
}

.pagination__item--page .pagination__anchor {
	opacity: 0.2;
}

.pagination__item--page .pagination__anchor.selected,
.pagination__item--page .pagination__anchor:hover {
	opacity: 1;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {


	.pagination__item--page {
		display: none;
	}

	.pagination__wrapper:hover .pagination__item--page {
		display: inline;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {


}