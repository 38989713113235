
/*
** vignette
*/

.vignette {
	position: relative;
}

.vignette:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	content: '';
	background: transparent;
}

.vignette img,
.vignette svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}