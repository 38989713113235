
/*
** video
*/

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.section--video .videos__container {
		position: relative;
		left: -0.1em;
		width: calc(100% + 0.2em);

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: none;
	}

	.section--video .videos__item {
		width: 50%;
	}

	.section--video .videos__wrapper.wrapper {
		padding-top: 0em;
		padding-left: 0.1em;
		padding-right: 0.1em;
		padding-bottom: 4em;
	}

	.section--video .videos__item:nth-child(odd) .videos__text {
		padding-left: 2em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}