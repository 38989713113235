
/*
** pdfs
*/

.pdfs {

}

.pdfs__container {

}

.pdfs__label {
	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);
}

.pdfs__content {
	margin-top: 1em;
}

.pdfs__item:first-child {
	border-top: 1px solid currentColor;
	border-top: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.pdfs__item {
	padding: 0.3em 0em;
	border-bottom: 1px solid currentColor;
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.pdfs__anchor {
	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}

.pdfs__anchor:hover {
	opacity: 0.3;
}

.pdfs__anchor svg {
	width: 1.2em;
	height: 1.2em;
}