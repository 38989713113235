
/*
** images
*/

.images {

}

.images__container {

}

.images__item {

}

.images__wrapper {

}

.images__content {
	position: relative;
}

.images__thumbnail {

}

.images__text {
	margin-top: 1em;

	opacity: 0.5;
}

.images__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;

	background: transparent;
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.images__container {
		position: relative;
		left: -2em;
		width: calc(100% + 4em);

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.images__item {
		width: 33.3%;

		padding-left: 2em;
		padding-right: 2em;
		margin-bottom: 4em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.images__item {
		margin-bottom: 1em;
	}
}