
/*
** representations
*/

.representations {

}

.representations__item {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 1em;
	align-items: center;

	padding-top: 1em;
	padding-bottom: 1em;
	border-bottom: 1px solid currentColor;
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.representations__period {
	font-family : var(--medium-ff);
	font-weight: var(--medium-fw);
	font-size: var(--medium-fs);
	letter-spacing: var(--medium-ls);
	line-height: var(--medium-lh);
	color: var(--medium-c);
}

.representations__time {
	justify-self: end;

	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}