
/*
** button
*/

.button {
	position: relative;
	display: inline-block;
	vertical-align: top;

	cursor: pointer;
	color: inherit;

	user-select: none;
	transition: opacity 300ms;
}

.button:hover {
	color: inherit;
}

.button__svg {
	display: inline-block;
	vertical-align: middle;

	color: inherit;
}

.button__label {
	display: inline-block;
	vertical-align: middle;

	color: inherit;
}