
/*
** paragraphs - complexe
*/

.paragraphs__item.complexe {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.paragraphs__item.complexe .paragraphs__wrapper {
		display: grid;
		column-gap: 5em;
		grid-template-columns: 1fr 1fr;
	}

	.paragraphs__item.complexe .paragraphs__image {
		grid-row: 1;
		grid-column: 2;
	}

	.paragraphs__item.complexe .paragraphs__info {
		grid-row: 1;
		grid-column: 1;
	}

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.paragraphs__item.complexe .paragraphs__info {
		margin-top: 2em;
	}
}