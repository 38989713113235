
/*
** content
*/

.content {
	position: relative;
	z-index: 2;
	background-color: var(--bgcolor);
}

.content__top {
	padding: 2em;
}

.content__top svg {
	margin-bottom: 2em;
}