
/*
** browser upgrade
*/

.browserupgrade {
	position: fixed;
	padding: 5em 0em;

	top: 40%;
	left: 0;
	width: 100%;
	z-index: 99999999;

	background: yellow;
	text-align: center;
}

.browserupgrade a {
	text-decoration: underline;
}