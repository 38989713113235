
/*
** lazyload
*/

img.lazyload {
	display: block;
	border: 0;
	opacity: 0;
}

img.lazyload:not(.initial) {
	transition: opacity 1s;
}

img.lazyload.initial,
img.lazyload.loaded,
img.lazyload.error {
	opacity: 1;
}

img.lazyload:not([src]) {
	visibility: hidden;
}