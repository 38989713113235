
/*
** page
*/

.page {
	position: relative;
}

.page__wrapper {

}

.page__back {

}

.page__block {

}

.page__video {
	position: relative;
	height: 100vh;
	overflow: hidden;
}

.page__video video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: cover;
	overflow: hidden;
}

.page__banner {
	margin: auto;
	text-align: center;
}

.page__banner .wysiwyg.wysiwyg--medium h3 {
	font-family : var(--4xlarge-ff);
	font-weight: var(--4xlarge-fw);
	font-size: var(--4xlarge-fs);
	letter-spacing: var(--4xlarge-ls);
	line-height: var(--4xlarge-lh);
	color: var(--4xlarge-c);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page {
		padding-top: 9em;
		padding-bottom: 10em;
	}

	.page__back {
		position: absolute;
		top: 1.5em;
		left: var(--padding-wrapper);
		z-index: 1;
	}

	.page__pdfs {
		margin-top: 2em;
	}

	.page__block,
	.page__banner {
		margin-top: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page {
		padding-top: 5em;
		padding-bottom: 3em;
	}

	.page__back {
		text-align: center;
	}

	.page__pdfs,
	.page__block,
	.page__banner {
		margin-top: 2em;
	}
}