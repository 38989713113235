
/*
** navigation
*/

.navigation {
	display: flex;
	column-gap: 2em;
}

.navigation__label,
.navigation__anchor {
	display: block;

	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase;
}

.navigation__anchor:hover {
	opacity: 0.3;
}