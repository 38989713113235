
/*
** sections
*/

.sections {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sections__item {

}

.sections__anchor {

}

.sections__anchor:hover,
.sections__anchor.selected {

}

.sections__name {

}

.sections__pages {

}