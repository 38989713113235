
/*
** observable
*/

*[observable] {

}

*[observable].observed {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	*[observable] {
		opacity: 0;
		transform: translateY(20%);
		transition: opacity 700ms ease-out, transform 700ms ease-out;
	}

	*[observable].observed {
			opacity: 1;
			transform: translateY(0);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}