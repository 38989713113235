

/* PP Neue Montreal */
@font-face {
	font-family: 'PP Neue Montreal';
	src: url('/assets/font/PPNeueMontreal-Regular.woff2') format('woff2'),
		url('/assets/font/PPNeueMontreal-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* PP Formula Semi Cond */
@font-face {
	font-family: 'PP Formula Semi';
	src: url('/assets/font/PPFormula-SemiCondensedExtralight.woff2') format('woff2'),
		url('/assets/font/PPFormula-SemiCondensedExtralight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PP Formula Semi';
	src: url('/assets/font/PPFormula-SemiCondensedMedium.woff2') format('woff2'),
		url('/assets/font/PPFormula-SemiCondensedMedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/* PP Formula Compressed */
@font-face {
	font-family: 'PP Formula';
	src: url('/assets/font/PPFormula-CondensedBlack.woff2') format('woff2'),
		url('/assets/font/PPFormula-CondensedBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}