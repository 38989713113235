
/*
** sections - 3
*/

.sections.sections-3 {

}

.sections.sections-3 .sections__item {

}

.sections.sections-3 .sections__anchor {
	font-family : var(--2xsmall-ff);
	font-weight: var(--2xsmall-fw);
	font-size: var(--2xsmall-fs);
	letter-spacing: var(--2xsmall-ls);
	line-height: var(--2xsmall-lh);
	color: var(--2xsmall-c);
}

.sections.sections-3 .sections__anchor:hover,
.sections.sections-3 .sections__anchor.selected {
	opacity: 0.5;
}