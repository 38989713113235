

/*
** paragraphs - simple
*/

.paragraphs__item.simple {

}

.paragraphs__item.simple .paragraphs__wrapper {
	max-width: var(--block-width);
	margin: auto;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.paragraphs__item.simple .paragraphs__icon {
		display: block;
		position: absolute;
		top: 1em;
		left: 1em;
		z-index: 1;
	}

	/* articles */
	.paragraphs__item.simple .articles__container {
		margin-left: calc( ((100vw - var(--block-width)) / 2) + var(--padding-wrapper) );
	}

	/* works */
	.paragraphs__item.simple .works__name {
		max-width: var(--block-width);
		margin: auto;
		padding-left: 2em;
		text-align: left;
	}

	.paragraphs__item.simple .works__name svg:first-child {
		display: none;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}