
/*
** wysiwyg - medium
*/

.wysiwyg.wysiwyg--medium h3 {
	display: block;
	margin-bottom: 0.5rem;

	font-family : var(--3xlarge-ff);
	font-weight: var(--3xlarge-fw);
	font-size: var(--3xlarge-fs);
	letter-spacing: var(--3xlarge-ls);
	line-height: var(--3xlarge-lh);
	color: var(--3xlarge-c);
}

.wysiwyg.wysiwyg--medium p {
	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}

.wysiwyg.wysiwyg--full ul {
	display: block;

	font-family : var(--normal-ff);
	font-weight: var(--normal-fw);
	font-size: var(--normal-fs);
	letter-spacing: var(--normal-ls);
	line-height: var(--normal-lh);
	color: var(--normal-c);
}