
/*
** block
*/

.block {

}

.block__label {
	font-family : var(--3xlarge-ff);
	font-weight: var(--3xlarge-fw);
	font-size: var(--3xlarge-fs);
	letter-spacing: var(--3xlarge-ls);
	line-height: var(--3xlarge-lh);
	color: var(--3xlarge-c);

	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;

	border-top: 1px solid color-mix(in srgb, currentColor 20%, transparent);
	border-bottom: 1px solid color-mix(in srgb, currentColor 20%, transparent);
}

.block__content {

}

/* works */
.block__content .works__item:first-child .works__info {
	border-top: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}