
/*
** pages
*/

.pages {
	margin: 0;
	padding: 0;
	list-style: none;
}

.pages__item {

}

.pages__anchor {
	font-family : var(--xlarge-ff);
	font-weight: var(--xlarge-fw);
	font-size: var(--xlarge-fs);
	letter-spacing: var(--xlarge-ls);
	line-height: var(--xlarge-lh);
	color: var(--xlarge-c);
}

.pages__anchor:hover,
.pages__anchor.selected {
	opacity: 0.3;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.pages__item:not(:last-child) {
		margin-bottom: 1em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.pages__item:not(:last-child) {
		margin-bottom: 0.5em;
	}
}
