
/*
** embed
*/

.embed {

}

.embed iframe {

}

.embed p {
	display: none;
}