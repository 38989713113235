
/*
** header - animation
*/



/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	/* sections-1 / Langs */
	.sections-1 .sections__item {
		opacity: 1;
		transform: translateY(150%);
		transition: all 600ms;
	}

	.sections-1 .sections__item.is-pages.selected {
		position: relative;
		z-index: 100;
	}

	.sections-1 .sections__item:nth-child(5) { transition-delay: 0ms;  }
	.sections-1 .sections__item:nth-child(4) { transition-delay: 50ms; }
	.sections-1 .sections__item:nth-child(3) { transition-delay: 100ms; }
	.sections-1 .sections__item:nth-child(2) { transition-delay: 150ms; }
	.sections-1 .sections__item:nth-child(1) { transition-delay: 200ms; }

	body.body--header-opened .sections-1 .sections__item:nth-child(1) { transition-delay: 0ms;  }
	body.body--header-opened .sections-1 .sections__item:nth-child(2) { transition-delay: 50ms; }
	body.body--header-opened .sections-1 .sections__item:nth-child(3) { transition-delay: 100ms; }
	body.body--header-opened .sections-1 .sections__item:nth-child(4) { transition-delay: 150ms; }
	body.body--header-opened .sections-1 .sections__item:nth-child(5) { transition-delay: 200ms; }

	body.body--header-opened .sections.sections-1 .sections__item {
		transform: translateY(0em);
		opacity: 1;
	}

	/* sections-2 / Langs */
	.header .sections-2 .sections__item,
	.header .langs__item {
		opacity: 0;
		transform: translateY(1rem);
		transition: all 600ms;
	}

	.header .langs__item { transition-delay: 0ms; }
	.header .sections-2 .sections__item:nth-child(4) { transition-delay: 50ms;  }
	.header .sections-2 .sections__item:nth-child(3) { transition-delay: 100ms; }
	.header .sections-2 .sections__item:nth-child(2) { transition-delay: 150ms; }
	.header .sections-2 .sections__item:nth-child(1) { transition-delay: 200ms; }

	body.body--header-opened .header .sections-2 .sections__item,
	body.body--header-opened .header .langs__item {
		transform: translateY(0em);
		opacity: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}


