
/* normalize */
@import url("./normalize.css");

/* main */
@import url("./font.css");
@import url("./root.css");
@import url("./main.css");

/* class */
@import url("./class/anchor.css");
@import url("./class/box.css");
@import url("./class/browserupgrade.css");
@import url("./class/button.css");
@import url("./class/close.css");
@import url("./class/diapositive.css");
@import url("./class/embed.css");
@import url("./class/lazyload.css");
@import url("./class/loader.css");
@import url("./class/marquee.css");
@import url("./class/nprogress.css");
@import url("./class/observable.css");
@import url("./class/preloader.css");
@import url("./class/underline.css");
@import url("./class/vignette.css");

/* block : articles */
@import url("./block/articles/articles.css");
@import url("./block/articles/swiper.css");

/* block : calendar */
@import url("./block/calendar/members.css");
@import url("./block/calendar/more.css");
@import url("./block/calendar/navigation.css");
@import url("./block/calendar/productions.css");
@import url("./block/calendar/representations.css");

/* block : images */
@import url("./block/images/images.css");
@import url("./block/images/swiper.css");

/* block : page */
@import url("./block/page/page/page-article.css");
@import url("./block/page/page/page-production.css");
@import url("./block/page/page/page-simple.css");
@import url("./block/page/page/page-team.css");
@import url("./block/page/page/page-work.css");
@import url("./block/page/page/page.css");

@import url("./block/page/block.css");
@import url("./block/page/pdfs.css");
@import url("./block/page/strip.css");
@import url("./block/page/videos.css");

/* block : paragraphs */
@import url("./block/paragraphs/panels.css");
@import url("./block/paragraphs/paragraphs-complexe.css");
@import url("./block/paragraphs/paragraphs-fullscreen.css");
@import url("./block/paragraphs/paragraphs-simple.css");
@import url("./block/paragraphs/paragraphs.css");

/* block : shortcode */
@import url("./block/shortcode/image.css");

/* block : teams */
@import url("./block/teams/teams.css");

/* block : works */
@import url("./block/works/list.css");
@import url("./block/works/works.css");

/* block : wysiwyg */
@import url("./block/wysiwyg/wysiwyg-full.css");
@import url("./block/wysiwyg/wysiwyg-medium.css");
@import url("./block/wysiwyg/wysiwyg-simple.css");
@import url("./block/wysiwyg/wysiwyg.css");

/* navigation */
@import url("./navigation/categories/categories.css");

@import url("./navigation/sections/langs.css");
@import url("./navigation/sections/pages.css");
@import url("./navigation/sections/sections-1.css");
@import url("./navigation/sections/sections-2.css");
@import url("./navigation/sections/sections-3.css");
@import url("./navigation/sections/sections.css");

@import url("./navigation/back.css");
@import url("./navigation/burger.css");
@import url("./navigation/cursor.css");
@import url("./navigation/logo.css");
@import url("./navigation/pagination.css");
@import url("./navigation/partners.css");
@import url("./navigation/socials.css");
@import url("./navigation/top.css");

/* layout */
@import url("./layout/header/header-animation.css");
@import url("./layout/header/header.css");

@import url("./layout/content.css");
@import url("./layout/footer.css");
@import url("./layout/main.css");
@import url("./layout/section.css");
@import url("./layout/wrapper.css");

/* section */
@import url("./section/calendar.css");
@import url("./section/home.css");
@import url("./section/teams.css");
@import url("./section/video.css");
@import url("./section/works.css");


/* dependencies */
@import url("../../node_modules/swiper/swiper-bundle.css");

