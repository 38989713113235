
/*
** cursor
** z-index: 1;
*/

.cursor {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;

	margin-top: 1em;
	margin-left: 1em;

	pointer-events: none;
}

.cursor.showed {

}

.cursor__content {

}

.cursor.showed .cursor__content {

}

.cursor__content img.lazyload:not(.initial) {
	transition: opacity 300ms ease;
}

/* tooltip */
.cursor .tooltip__thumbnail {
	display: block;
	width: 30vw;
	box-shadow: 0 0.2em 0.4em 0 rgba(0, 0, 0, 0.15);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 821px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 820px ) {

	.cursor {
		display: none;
	}
}