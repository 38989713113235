
/*
** burger
*/

.burger {
	display: flex;
	align-items: center;
	column-gap: 0.7em;

	color: #fff;
	mix-blend-mode: exclusion;
	user-select: none;
}

.burger__label {
	display: block;

	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase;
	transition: opacity 300ms;
}

.burger:hover .burger__label {

}

.burger__open {
	display: block;

	width: 0.9em;
	height: 0.9em;

	border-radius: 1em;
	background-color: currentColor;
}

.burger__close,
body.body--header-opened .burger__open {
	display: none;
}

.burger__open,
body.body--header-opened .burger__close {
	display: block;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.burger__open {
		transition: transform 300ms;
	}

	.burger:hover .burger__open {
		transform-origin: right center;
		transform: scale(1.2);
	}

	.burger:hover .burger__label,
	body.body--header-opened .burger__label {
		opacity: 0;
		pointer-events: none;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.burger__label {
		display: none;
	}
}