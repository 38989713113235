
/*
** more
*/

.more {
	display: inline-block;
	padding: 1em 2em;

	border-radius: 1em;
	box-shadow: 0 0.2em 0.4em 0 rgba(0, 0, 0, 0.15);
}

.more .button__svg {
	width: 3em;
	height: 3em;
	transform: scale(2) translateX(-0.2em);
}

.more .button__label {
	font-family : var(--2xlarge-ff);
	font-weight: var(--2xlarge-fw);
	font-size: var(--2xlarge-fs);
	letter-spacing: var(--2xlarge-ls);
	line-height: var(--2xlarge-lh);
	color: var(--2xlarge-c);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	/* hover */
	.more {
		transition: transform 600ms, opacity 300ms;
	}

	.more:hover {
		opacity: 0.5;
		transform: translateY(-1rem);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}
