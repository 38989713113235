
/*
** categories
*/

.categories {
	margin: 0;
	padding: 0;
	list-style: none;
}

.categories__item:not(:last-child) {
	margin-bottom: 0.1em;
}

.categories__anchor {
	font-family : var(--small-ff);
	font-weight: var(--small-fw);
	font-size: var(--small-fs);
	letter-spacing: var(--small-ls);
	line-height: var(--small-lh);
	color: var(--small-c);

	opacity: 0.3;
}

.categories__anchor:hover,
.categories__anchor.selected {
	opacity: 1;
}