
/*
** anchor
*/

.anchor,
.anchor:hover {
	cursor: pointer;
	user-select: none;
	transition: opacity 300ms;
}