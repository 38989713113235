
/*
** logo
*/

.logo {

}

.logo__anchor {

}


.logo__svg {

}

.logo__title {
	display: none;
}