
/*
** page - team
*/

.page.team .page__content {

}

.page.team .page__name {
	font-family : var(--6xlarge-ff);
	font-weight: var(--6xlarge-fw);
	font-size: var(--6xlarge-fs);
	letter-spacing: var(--6xlarge-ls);
	line-height: var(--6xlarge-lh);
	color: var(--6xlarge-c);

	text-align: center;
}

.page.team .page__function {
	margin-top: 0.5em;

	font-family : var(--xsmall-ff);
	font-weight: var(--xsmall-fw);
	font-size: var(--xsmall-fs);
	letter-spacing: var(--xsmall-ls);
	line-height: var(--xsmall-lh);
	color: var(--xsmall-c);

	text-align: center;
}

.page.team .page__info {
	max-width: var(--block-width);
	margin: auto;
}

.page.team .page__image {
	margin: auto;
	margin-top: 2em;
}

.page.team .page__text {
	text-align: left;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.team .page__image.portrait {
		width: 75%;
	}

	.page.team .page__text {
		margin-top: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page.team .page__text{
		margin-top: 2em;
	}
}