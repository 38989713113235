
/*
** back
*/

.back {

}

.back__anchor {
	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase;
}

.back__anchor:hover {
	opacity: 0.3;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.back svg {
		display: block;
		margin-left: 0.7em;
		width: 10em;
		height: 3em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.back svg {
		display: none;
	}
}
