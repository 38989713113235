
/*
** box
*/

.box {
	position: relative;
	display: inline-block;
	padding: 0.3em 1em 0.4em;
}

.box.year {
	padding: 0.25em 0.5em 0.10em;
}

.box:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	content: '';
	border: 1px solid currentColor;
	border-radius: 2em;
	background-color: transparent;
}

/* a */
a.box:after {
	border-radius: 0.5em;
	transition: all 600ms ease;
}

a.box:hover {
	color: var(--bgcolor);
}

a.box:hover:after {
	border-radius: 2em;
	background-color: var(--color);
}