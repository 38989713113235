
/*
** sections - 1
*/

.sections.sections-1 {
	position: relative;
	transform: translateY(-0.05em);
}

.sections.sections-1 .sections__item:not(:last-child) {
	margin-bottom: 0.5em;
}

.sections.sections-1 .sections__anchor {
	font-family : var(--6xlarge-ff);
	font-weight: var(--6xlarge-fw);
	font-size: var(--6xlarge-fs);
	letter-spacing: var(--6xlarge-ls);
	line-height: var(--6xlarge-lh);
	color: var(--6xlarge-c);

	text-transform: uppercase
}

.sections.sections-1 .sections__anchor:hover,
.sections.sections-1 .sections__item.selected .sections__anchor,
.sections.sections-1 .sections__anchor.selected {
	color: transparent;
	-webkit-text-stroke: 1px var(--color);
	text-stroke: 1px var(--color);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	/* pages */
	.sections.sections-1 .pages {
		position: absolute;
		top: 0em;
		left: 24em;
		z-index: 1;

		opacity: 0;
		pointer-events: none;
		transition: opacity 600ms;
	}

	.sections.sections-1 .sections__item.selected .pages {
		opacity: 1;
		pointer-events: auto;
	}

	.sections.sections-1 .pages:after {
		position: absolute;
		top: 0;
		left: -2em;
		width: 1px;
		height: 100%;
		z-index: 1;

		content: '';
		background-color: var(--color);
		opacity: 0.2;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.sections.sections-1 .pages {
		padding-top: 1em;
		padding-bottom: 2em;
		display: none;
	}

	.sections.sections-1 .sections__item.selected .pages {
		display: block;
	}
}