
/*
** wysiwyg - simple
*/

.wysiwyg.wysiwyg--simple p {
	font-family : var(--2xsmall-ff);
	font-weight: var(--2xsmall-fw);
	font-size: var(--2xsmall-fs);
	letter-spacing: var(--2xsmall-ls);
	line-height: var(--2xsmall-lh);
	color: var(--2xsmall-c);
}