
/*
** page - simple
*/

/* info */
.page.simple .page__info {
	margin: auto;
	text-align: center;
}

.page.simple .page__name {
	font-family : var(--3xsmall-ff);
	font-weight: var(--3xsmall-fw);
	font-size: var(--3xsmall-fs);
	letter-spacing: var(--3xsmall-ls);
	line-height: var(--3xsmall-lh);
	color: var(--3xsmall-c);

	text-transform: uppercase;
	opacity: 0.3;
}

.page.simple .page__text {
	margin-top: 0.7em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.simple .page__pagination {
		margin-top: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page.simple .page__pagination {
		margin-top: 2em;
	}
}