
/*
** page - work
*/

.page.work .page__name {
	font-family : var(--6xlarge-ff);
	font-weight: var(--6xlarge-fw);
	font-size: var(--6xlarge-fs);
	letter-spacing: var(--6xlarge-ls);
	line-height: var(--6xlarge-lh);
	color: var(--6xlarge-c);
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.work .page__info {
		margin-top: 5em;
	}

	.page.work .page__image {
		margin-bottom: 5em;
	}

	.page.work .page__image,
	.page.work .mw-l {
		margin-left: 30%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}