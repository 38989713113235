
/*
** page - production
*/



.page.production .page__period {
	margin-bottom: 0.2em;

	font-family : var(--xlarge-ff);
	font-weight: var(--xlarge-fw);
	font-size: var(--xlarge-fs);
	letter-spacing: var(--xlarge-ls);
	line-height: var(--xlarge-lh);
	color: var(--xlarge-c);

	text-transform: uppercase;
}

.page.production .page__name {
	font-family : var(--6xlarge-ff);
	font-weight: var(--6xlarge-fw);
	font-size: var(--6xlarge-fs);
	letter-spacing: var(--6xlarge-ls);
	line-height: var(--6xlarge-lh);
	color: var(--6xlarge-c);
}

.page.production .page__place {
	font-family : var(--xsmall-ff);
	font-weight: var(--xsmall-fw);
	font-size: var(--xsmall-fs);
	letter-spacing: var(--xsmall-ls);
	line-height: var(--xsmall-lh);
	color: var(--xsmall-c);
}

.page.production .page__image img {
	width: 100%;
	height: auto !important;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.production .page__visual {
		margin-top: 5em;
	}

	.page.production .page__visual,
	.page.production .mw-l {
		margin-left: 30%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}